/* @import "video-react/styles/scss/video-react"; */


::placeholder {
  color: #fff
}

*:focus {
  outline: none;
}


.video-react {
  padding: 0% !important;
  height: 100% !important
}

.video-react-big-play-button {
  display: none !important
}

.video-react-video {
  width: 100% !important;
  position: relative !important;
}

/* .video-react-control-bar {
  display: none
} */

.scrollbar-track {
  background-color: transparent !important;
}



.scrollbar-thumb {
  width: 10px !important;
  background-color: black !important;
  border-radius: 25 !important;
}


.__rcs-handle-button div {
  border-top: 6px solid transparent !important;
  border-right: 9px solid !important;
  border-bottom: 6px solid transparent !important;
}

@media screen and (max-width: 600px) {
  .scrollbar-track {
    display: none;
  }



  .scrollbar-thumb {
    display: none;
  }
}